import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';


export default class extends Controller {

  static targets = ['focus']

  connect() {
    StimulusReflex.register(this)
  }

  initialize() {
    // We need to know when there are new messages that have been created by other users
    consumer.subscriptions.create({ channel: "ChatChannel", room: "member" }, {
      received: this._cableReceived.bind(this),
    });
    consumer.subscriptions.create({ channel: "ChatChannel", room: "organisation" }, {
      received: this._cableReceived.bind(this),
    });
  }

  create_message(event) {
    event.preventDefault()    
    this.stimulate('Chat#create_message', event.target).then(() => {
      //this.element.reset()
      // optional: set focus on the freshly cleared input
      //this.focusTarget.focus()
      var textboxes = document.getElementsByClassName("chat-message");
      var i;
      for (i = 0; i < textboxes.length; i++) {
        textboxes[i].value = "";
      }
    })
  }

  _cableReceived() {
    this.stimulate('Chat#update_messages')
    // var textboxes = document.getElementsByClassName("chat-message");
    // var i;
    // for (i = 0; i < textboxes.length; i++) {
    //   textboxes[i].value = "";
    // }

    //document.getElementById("toggle-chat-id").classList.remove('hidden');
    // window.onload = function(){
    //   el = document.getElementById('memberchatscrollbar');
    //   el.scrollTop=el.scrollHeight * -1;
    // }
    // console.log("UPDATE MORph");
    //document.getElementById('memberchatscrollbar').scrollTop = -20000;
    // var controlled_element = $("*[data-controller='toggle']").first()
    // if (controlled_element != undefined) {
    //     if (this.application.getControllerForElementAndIdentifier(controlled_element, "toggle") == undefined) {
    //         console.log("FAILED: could not find the controller");
    //     }
    // }
    //document.getElementById("show_event_left_col").classList.add('hidden');
    
    //document.getElementById("show_event_left_col").classList.hasAttribut('hidden');
    //console.log("my object: %o", this)
    // var element = document.getElementById("member-panel")
    // element.classList.remove('hidden')
    // element.className = element.className.replace(/\bhidden\b/g, "");

    
  }
}
