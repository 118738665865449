import { Controller } from "stimulus"
//import StimulusReflex from 'stimulus_reflex'
import Rails from "@rails/ujs";
export default class extends Controller {
  //static targets = [ "link" ]
  //static values = { item: String }
  connect() {
    //this.outputTarget.textContent = 'Hello, Stimulus!'
    //StimulusReflex.register(this)
  }

  save(event) { 
    //event.preventDefault()
    //console.log(this.item)
    console.log(this.data.get("item"))
    //console.log(this.usertrackingItem)
    //this.stimulate("Usertracking#visited",[[],[],{"item": this.data.get("item")}])
    //this.stimulate("Usertracking#visited", event.target)
    Rails.ajax({
      type: "post",
      url: "/user_trackings?item=" + this.data.get("item"),
      data: {user_trackings: {item: "jjjjjjjjjjjjjjjj"}}
    })
  }
}