// dropdown_controller.js
import {Controller} from "stimulus"
// @ts-ignore
import {enter, leave} from 'el-transition'

export default class extends Controller {
    static targets = ["menu", "button"]

    toggleMenu() {
        if (this.menuTarget.classList.contains('hidden')) {
            enter(this.menuTarget)
        } else {
            leave(this.menuTarget)
        }
    }
    hideMenu(event) {
        const buttonClicked = this.buttonTarget.contains(event.target)
    
        if (!buttonClicked) {
          leave(this.menuTarget)
        }
      }
    
}
