import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import CableReady from 'cable_ready'

export default class extends Controller {

  connect () {
    super.connect()
    // ???? consumer oder subscription wird nicht erkannt - warum verstehe ich nicht.
    // this.channel = consumer.subscriptions.create('SupplyChannel', {
    //   connected: this._cableConnected.bind(this),
    //   disconnected: this._cableDisconnected.bind(this),
    //   received: this._cableReceived.bind(this),
    // });
    // this.channel = this.application.consumer.subscriptions.create('SupplyChannel', {
    //   received (data) {
    //     if (data.cableReady) CableReady.perform(data.operations)
    //   }
    // })
    StimulusReflex.register(this)
  }
  initialize() {
    // We need to know when there are new messages that have been created by other users
    this.channel = consumer.subscriptions.create({ channel: "SupplyChannel" }, {
      received: this._cableReceived.bind(this),
    });
  }
  show_admin(){
    document.getElementById("supply").classList.remove('hidden')
  }

  show_all(event){
    event.preventDefault()
    this.stimulate('supply#show_all', event.target)
    //this.channel.send(document.getElementById("supply").classList.remove('hidden'))
    //CableReady.DOMOperations.addCssClass(selector: 'supply', )
  }
  hide_all(event){
    event.preventDefault()
    this.stimulate('supply#hide_all', event.target)
    //document.getElementById("supply").classList.add('hidden')
  }
  _cableReceived() {
    //this.stimulate('Supply#show_all')
  }
}