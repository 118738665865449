import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create("SupplyChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    //wenn Daten kommen, dann mit CableReady cerarbeiten
    console.log("JS-CHannel-suppl_channel")
    if (data.cableReady) CableReady.perform(data.operations)
  }
});
