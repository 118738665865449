import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable']
  static values = { open: Boolean }

  connect() {
    //connect wird aufgerufen, wenn die Seite geladen wird
    this.toggleClass = this.data.get('class') || 'hidden'

    //schliesst das Element beim start
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
  }

  toggle(event) {
    event.preventDefault()
    // el = document.getElementById('memberchatscrollbar');
    // el.scrollTop=el.scrollHeight * -1;
    this.openValue = !this.openValue
    
  }

  openValueChanged() {
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass)
    })
  }
}
